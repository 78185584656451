@import "../../style/scss-variables";

.contact {
  display: flex;
  width: 100%;
  align-items: center;
  height: auto;
  flex-wrap: wrap;

  .__container_contact {
    display: flex;
    height: auto;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-evenly;

    .__box {
      padding: 2rem;
      height: 100%;
      width: 55%;
      border: 3px solid $clear-gray;
      border-radius: 22px;

      .__infoBox {
        display: flex;
        justify-content: center;
        position: relative;
        height: 23rem;
        color: silver;
        text-transform: uppercase;
        text-align: center;
        z-index: 1;
        flex-direction: column;
        font-size: 1.5rem;
        border-radius: 22px;
        white-space: pre-wrap;
        line-height: 1.5;

        .__logo {
          padding: 1rem;
          z-index: -1;
          position: absolute;
          top: 0;
          left: 0;
          display: block;
          width: 100%;
          height: 100%;
          border-radius: 22px;
          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 100%;
            background: $bg-linear-gradt;
            opacity: 1;
            border-radius: 19px;
          }

          img {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 15px;
            flex-direction: column;
            object-fit: fill;
            z-index: -1;
          }
        }
        .__arrow {
          width: 100%;
          height: auto;
          display: none;
          position: relative;
          left: 50%;
          transform: translate(-50%);
          bottom: 0;
          cursor: pointer;
          z-index: 999;
          padding: 33px;
          img {
            width: 4rem;
            height: 100%;
          }
        }
      }
    }

    .__form {
      height: auto;
      display: flex;
      width: 40%;
      justify-content: center;
      border: 3px solid $clear-gray;
      border-radius: 22px;
    }

    .__icons {
      display: flex;
      width: 50%;
      height: 100%;
      padding: 2rem;
      justify-content: space-evenly;
      margin: auto;
    }
  }
}

//////////////////////// RESPONSIVE ///////////////////////////////
@media only screen and (max-width: 820px) {
  .contact {
    height: auto;
    .__container_contact {
      padding: 1rem;
      .__box {
        width: 100%;
        padding: 0.7rem;
        margin-bottom: 1rem;
        .__infoBox {
          height: 24rem;
          font-size: 1.5rem;
          justify-content: start;
          font-size: 2rem;
          .__arrow {
            display: block;
            img {
              width: 3.5rem;
              height: 100%;
            }
          }
        }
      }
      .__form {
        height: auto;
        display: flex;
        width: 100%;
      }
      .__icons {
        width: 100%;
        padding: 1rem;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .contact {
    height: auto;
    .__container_contact {
      padding: 0;
      .__box {
        .__infoBox {
          height: 15rem;
          font-size: 1rem;
          justify-content: start;
          .__arrow {
            display: block;
          }
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .contact {
    height: auto;
    .__container_contact {
      .__box {
        width: 100%;
        .__infoBox {
          font-size: 1rem;
        }
      }
      .__form {
        height: auto;
        display: flex;
        width: 100%;
      }
      .__icons {
        display: flex;
        width: 100%;
        height: 100%;
      }
    }
  }
}
