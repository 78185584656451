@import "../../style/scss-variables";

.navbar {
  background: $bg-linear-gradt;
  display: flex;
  position: sticky;
  top: 0;
  align-items: center;
  width: 100%;
  height: 114px;
  justify-content: flex-end;
  padding: 0 2rem;

  &.fixed {
    background: $bg-linear-gradt;
    transition: 2s ease-in-out;
    -webkit-transition: 2s ease-in-out;
    -moz-transition: 2s ease-in-out;
    -o-transition: 2s ease-in-out;
    z-index: 999;
    width: 100%;
  }

  .__logo {
    max-height: 4rem;
    height: auto;
    display: flex;
    cursor: pointer;
    z-index: 4;
    padding: 5px;
  }

  .__nav_menu {
    align-items: center;
    position: relative;
    text-align: center;
    width: 100%;
    height: 100%;

    .__menu_content {
      display: flex;
      width: 100%;
      justify-content: flex-end;
      align-items: center;
      height: auto;

      .__icons {
        display: flex;
        width: 16%;
        height: 100%;
        padding: 2rem;
      }
    }
  }

  .__nav_burger_menu {
    display: block;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 1;

    .__summ {
      font-display: block;
      border: none;
      position: absolute;
      background: none;
      cursor: pointer;
      display: flex;
      top: 33px;
      right: 19px;
      width: auto;
      z-index: 4;
      img {
        width: 52px;
      }
    }

    .__menu_mobile {
      flex-direction: column;
      background-color: #000;
      display: flex;
      border: none;
      align-items: center;
      justify-content: center;
      position: fixed;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100vh;
      padding: 2rem;
      text-align: center;
      right: 0;
      z-index: 3;
    }
  }
}

///////////////////////// RESPONSIVE //////////////////////
@media only screen and (min-width: 1200px) {
  .navbar {
    .__nav_menu {
      display: flex !important;
    }
    .__nav_burger_menu {
      display: none !important;
    }
  }
}

@media only screen and (max-width: 960px) {
  .navbar {
    background: #000;
    z-index: 999;
    .__nav_menu {
      display: none;
    }
    .__nav_burger_menu {
      display: block;
    }
  }
}

@media (min-width: 768px) {
  .navbar {
    z-index: 999;
    .__nav_menu {
      display: none;
    }
    .__nav_burger_menu {
      display: block;
    }
  }
}

@media (min-width: 501px) {
  .navbar {
    z-index: 999;
    .__nav_menu {
      display: none;
    }
    .__nav_burger_menu {
      display: block;
    }
  }
}

@media (max-width: 500px) and (min-width: 300px) {
  .navbar {
    z-index: 999;
    .__nav_menu {
      display: none;
    }
    .__nav_burger_menu {
      display: block;
    }
  }
}

@media (max-width: 501px) and (min-width: 320px) {
  .navbar {
    z-index: 999;
    .__nav_burger_menu {
      display: block;
    }
  }
}

@media (min-width: 320px) {
  .navbar {
    z-index: 999;
    .__nav_burger_menu {
      display: block;
    }
  }
}
