@import "../../style/scss-variables";

.wrapper {
  flex: 1;
  flex-wrap: wrap;
  justify-content: center;
  padding: 2rem;
  height: auto;

  .card {
    min-width: 13rem;
    display: flex;
    position: relative;
    height: 23rem;
    max-width: 33rem;
    border-radius: 22px;
    padding: 0.1rem;
    position: relative;
    align-items: flex-end;
    transition: 0.4s ease-out;
    -webkit-transition: 0.4s ease-out;
    -moz-transition: 0.4s ease-out;
    -o-transition: 0.4s ease-out;
    cursor: pointer;

    &:hover {
      transform: translateY(20px);

      &::before {
        opacity: 1;
      }

      .__infoBox {
        opacity: 1;
        transform: translateY(0px);
      }
    }
    .__infoBox {
      z-index: 3;
      color: #fff;
      opacity: 0;
      transform: translateY(30px);
      transition: 0.5s;
      -webkit-transition: 0.5s;
      -moz-transition: 0.5s;
      -o-transition: 0.5s;
      background: $bg-linear-gradt;
      width: 100%;
      height: 100%;
      border-radius: 17px;
      top: 0;
      left: 0;
      position: absolute;
      padding: 1rem;

      h2 {
        margin: 0;
      }

      p {
        font-size: 20px;
        margin: 8px 0 8px;
      }
    }

    .__btn_link {
      color: $clear-gray;
      font-weight: bold;
      cursor: pointer;
    }
    .__image {
      display: flex;
      width: 100%;
      height: 100%;
      position: relative;
      border: 2px solid #fff;
      border-radius: 22px;
      background: $clear-gray;

      img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 21px;
        flex-direction: column;
        object-fit: fill;
      }
    }

    a[href] {
      text-decoration: none;
      color: #fff;
      transition: color 0.3s ease-in-out;
    }

    a[href]:hover {
      color: #707070;
    }
  }
}

//////////////////////// RESPONSIVE ///////////////////////////////
@media only screen and (max-width: 820px) {
  .wrapper {
    padding: 1rem;
  }
}
