.sections {
  width: 100%;
  display: flex;
  overflow: hidden;
  padding: 3.5rem;
  min-height: 85vh;
}

//////////////////RESPONSIVE//////////////////////
@media only screen and (max-width: 1600px) {
  .sections {
    padding: 3rem;
    height: auto;
  }
}

@media only screen and (max-width: 768px) {
  .sections {
    padding: 2rem;
    height: auto;
  }
}
