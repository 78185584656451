@import "../../style/scss-variables";

.kpintch_container {
  width: 100%;
  height: 100%;
  align-items: center;
  position: relative;

  h4 {
    font-size: 18px;
    font-weight: lighter;
    text-transform: uppercase;
  }

  .__form_element {
    display: flex;

    label {
      font-size: 1.5rem;
      text-align: left;
      padding-top: 3rem;
      padding-right: 1rem;
    }

    input {
      font-size: $ruler;
      border-radius: 320px;
      padding: 19px;
      letter-spacing: -0.2px;
      box-shadow: inset 2px 2px 5px rgb(16, 17, 27),
        inset -5px -5px 10px $color-clear-dark;
      transition: all 0.2s ease-in-out;
      width: 100%;
      appearance: none;
      -webkit-appearance: none;
      resize: none;
      outline: none;
      border: none;
      height: 4rem;
      background-color: transparent;
      cursor: pointer;
      overflow: auto;
      color: #fff;
      margin-top: 2rem;

      &:focus {
        box-shadow: inset 1px 1px 2px $color-clear-dark,
          inset -1px -1px 2px #030303;
      }
      .__result {
        position: absolute;
        color: $clear-gray;
      }
    }

    .__result {
      position: absolute;
    }

    button {
      cursor: pointer;
      border: 0;
      outline: 0;
      padding: 16px;
      background-color: transparent;
      margin: 18px 14px;
      position: relative;
      box-shadow: -5px -5px 20px $color-clear-dark,
        5px 5px 20px $color-shadow-dark;
      appearance: none;
      -webkit-appearance: none;
      border-radius: 30%;
      margin-top: 2.7rem;
      &:hover {
        box-shadow: -2px -2px 5px $color-clear-dark,
          2px 2px 5px $color-shadow-dark;
      }

      &:active {
        box-shadow: inset 1px 1px 2px $color-shadow-dark,
          inset -1px -1px 2px $color-clear-dark;
      }

      .__arrow {
        display: flex;
        position: absolute;
        filter: brightness(0) invert(1);
        -webkit-filter: brightness(0) invert(1);
        top: 50%;
        transform: translateY(-50%);
        background: none;
        border: none;
        width: 2rem;
        right: 0;
      }
    }
  }
}
