@import "../../style/scss-variables";

.projects {
  display: flex;
  width: 100%;
  align-items: center;
  height: auto;

  .__container {
    display: flex;
    justify-content: center;
    width: 100%;
    height: auto;
    overflow: hidden;
    border: 3px solid $clear-gray;
    border-radius: 22px;
    flex-direction: column;
    line-height: 1.5;

    .__box {
      height: 100%;
      width: 100%;

      .__infoBox {
        display: flex;
        justify-content: center;
        position: relative;
        height: auto;
        text-align: center;
        z-index: 1;
        flex-direction: column;
        font-size: 1.5rem;
        border-radius: 22px;
        white-space: pre-wrap;
        height: 100%;
        padding: 2rem;

        .__logo {
          padding: 1rem;
          z-index: -1;
          position: absolute;
          top: 0;
          left: 0;
          display: block;
          width: 100%;
          height: 100%;
          border-radius: 22px;
          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 100%;
            background: $bg-linear-gradt;
            opacity: 1;
            border-radius: 22px;
          }

          img {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 15px;
            object-fit: fill;
            z-index: -1;
            padding: 1rem;
          }
        }

        h3 {
          display: block;
          padding: 2rem;
          text-align: center;
          text-transform: uppercase;
          color: $gray-color;
        }
      }
    }
    .__arrow {
      width: 100%;
      height: auto;
      display: flex;
      position: relative;
      align-items: center;
      bottom: 0;
      cursor: pointer;
      justify-content: center;
      img {
        width: 4rem;
        height: 100%;
      }
    }

    .__container_cards {
      display: flex;
      width: 100%;
      height: auto;
      align-items: center;
      flex-wrap: wrap;
      border-radius: 22px;
      padding-bottom: 2rem;
    }
  }
}
//////////////////RESPONSIVE//////////////////////
@media only screen and (max-width: 1440px) and (min-width: 1200px) {
  .projects {
    height: auto;
    .__container {
      height: 100%;
      .__box {
        .__infoBox {
          height: 100%;
          padding: 1rem;
        }
        .__arrow {
          img {
            width: 3.5rem;
            height: 100%;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 820px) {
  .projects {
    flex-direction: column;
    height: auto;
    .__container {
      height: 100%;
      .__box {
        .__infoBox {
          height: 100%;
          font-size: 1rem;
          text-align: start;
          padding: 1rem;
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .projects {
    height: auto;
    .__container {
      height: 100%;
      .__box {
        .__infoBox {
          font-size: 1rem;
        }
      }
    }
  }
}
