@import "../../style/scss-variables";

.welcome {
  display: flex;
  width: 100%;
  align-items: center;
  height: auto;
  position: relative;
  flex-wrap: wrap;

  .__container {
    overflow: hidden;
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: auto;
      background: linear-gradient(to bottom, #0a0c2c80 3rem, transparent 10rem);
      opacity: 1;
      z-index: -1;
    }

    .__welcome_container {
      display: flex;
      padding: 2rem;
      width: auto;
      height: auto;
      border: 3px solid $clear-gray;
      border-radius: 22px;

      .logo_container {
        width: auto;
        height: auto;
        justify-content: center;
        display: flex;

        .__logo {
          display: flex;
          height: auto;
          width: 100%;
          align-self: center;
          justify-content: center;

          img {
            max-width: 28rem;
            height: auto;
            object-fit: cover;
            padding: 1rem;
          }
        }
      }

      // &.__tentacles_container {
      //   height: auto;
      //   width: 5%;
      // }

      .text {
        display: flex;
        height: auto;
        white-space: pre-wrap;
        width: auto;
        flex-direction: column;
        line-height: 1.5;

        h1 {
          font-size: 3rem;
          margin-bottom: 1rem;
        }

        .__regular_text {
          width: 100%;
          height: auto;
          display: flex;
          flex-direction: column;
          padding: 2rem;

          p {
            font-size: 28px;
            color: #fff;
          }
        }

        .__text_mobile {
          width: 100%;
          height: auto;
          justify-content: flex-end;
          align-self: center;
          justify-content: center;
          display: none;
          line-height: normal;

          p {
            font-size: 16px;
          }
        }

        .__button_box {
          height: auto;
          align-content: center;
          display: flex;
          padding: 2rem;
        }
      }
    }
  }
}

//////////////////////// RESPONSIVE ///////////////////////////////
@media only screen and (max-width: 1440px) {
  .welcome {
    .__container {
      width: 100%;
      .__welcome_container {
        .logo_container {
          .__logo {
            img {
              width: 27rem;
            }
          }
        }
        .text {
          width: 100%;
          h1 {
            font-size: 1.9rem;
          }
          .__regular_text {
            display: block;
          }
          .__text_mobile {
            display: none;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .welcome {
    .__container {
      flex-direction: column;
      width: 100%;
      .__welcome_container {
        .logo_container {
          .__logo {
            img {
              width: 27rem;
            }
          }
        }
        .text {
          width: 100%;
          h1 {
            font-size: 1.9rem;
          }
          .__regular_text {
            display: block;
          }
          .__text_mobile {
            display: none;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 950px) and (min-width: 768px) {
  .welcome {
    .__container {
      flex-direction: column;
      width: 100%;
      .__welcome_container {
        flex-direction: column;
        padding: 1rem;
        .logo_container {
          .__logo {
            img {
              width: 14rem;
            }
          }
        }
        .text {
          width: 100%;
          h1 {
            font-size: 1.8rem;
          }
          .__regular_text {
            display: block;
            padding: 0.5rem;
            p {
              font-size: 25px;
            }
          }
          .__text_mobile {
            display: none;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .welcome {
    .__container {
      flex-direction: column;
      .__welcome_container {
        flex-direction: column;
        padding: 0.5rem;
        .logo_container {
          padding: 0;
          .__logo {
            img {
              width: 14rem;
            }
          }
        }
        .text {
          width: 100%;
          h1 {
            font-size: 19px;
          }
          .__regular_text {
            display: none;
          }
          .__text_mobile {
            display: block;
            padding: 0;
            text-align: left;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 375px) {
  .welcome {
    .__container {
      flex-direction: column;
      .__welcome_container {
        flex-direction: column;
        padding: 0.5rem;
        .logo_container {
          padding: 0;
          .__logo {
            img {
              width: 10rem;
            }
          }
        }
        .text {
          width: 100%;
          h1 {
            font-size: 17px;
          }
          .__regular_text {
            display: none;
          }
          .__text_mobile {
            display: block;
            padding: 0.5rem;
            text-align: left;
          }
          .__button_box {
            padding: 0.5rem;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 320px) {
  .welcome {
    width: auto;
    .__container {
      .__welcome_container {
        .logo_container {
          padding: 0;
          .__logo {
            img {
              width: 9rem;
            }
          }
        }
        .text {
          width: 100%;
          h1 {
            font-size: 17px;
          }
          .__regular_text {
            display: none;
          }
          .__text_mobile {
            display: block;
            padding: 0.5rem;
            text-align: left;
          }
          .__button_box {
            padding: 0.5rem;
          }
        }
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .welcome {
    height: 60vh;
    .__container {
      .__welcome_container {
        .logo_container {
          padding: 0;
          .__logo {
            img {
              width: 15rem;
            }
          }
        }
        .text {
          width: 100%;
          h1 {
            font-size: 19px;
          }
          .__regular_text {
            display: none;
          }
          .__text_mobile {
            display: block;

            padding: 1rem;
            text-align: left;
          }
          .__button_box {
            padding: 0.5rem;
          }
        }
      }
    }
  }
}
