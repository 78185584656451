@import "../../style/scss-variables";

.__link {
  display: flex;
  align-items: center;
  width: 14%;
  border-radius: 320px;
  height: 4rem;
  margin-left: 3rem;
  box-shadow: -5px -5px 20px $color-clear-dark, 5px 5px 20px $color-shadow-dark;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  border: 0;
  outline: 0;
  padding: 16px;
  background-color: transparent;
  opacity: 2;
  appearance: none;
  -webkit-appearance: none;

  &:hover {
    box-shadow: -2px -2px 5px $color-clear-dark, 2px 2px 5px $color-shadow-dark;
  }

  &:active {
    box-shadow: inset 1px 1px 2px $color-shadow-dark,
      inset -1px -1px 2px $color-clear-dark;
  }

  a {
    text-decoration: none;
    font-size: 24px;
    width: 100%;
    font-weight: bolder;
    position: relative;
    z-index: 11;
    padding: 1.5rem;
    cursor: pointer;
    color: #fff;
    &:hover {
      opacity: 1;
      transition: opacity 0.2s ease-out;
      -webkit-transition: opacity 0.2s ease-out;
      -moz-transition: opacity 0.2s ease-out;
      -o-transition: opacity 0.2s ease-out;
      color: #707070;
    }
  }
}

.__link_mobile {
  display: flex;
  align-items: center;
  position: relative;
  width: auto;
  height: auto;

  a {
    text-decoration: none;
    font-size: 24px;
    width: 100%;
    font-weight: bolder;
    position: relative;
    z-index: 11;
    padding: 1.5rem;
    cursor: pointer;
    color: #fff;
    &:hover {
      opacity: 1;
      transition: opacity 0.5s ease-out;
      color: silver;
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

//////////////////RESPONSIVE//////////////////////
@media only screen and (min-width: 400px) {
  .__link_mobile {
    a {
      font-size: 34px;
    }
  }
}
