// color
$btn-color: #707070;
$ruler: 16px;
$color-bg: #ebecf0;
$color-shadow-dark: #000;
$color-clear-dark: #2a2a2e;
// $color-shadow-dark: #0b0b0b;
$color-clear-dark: #2a2a2e;
$bg-linear-gradt: linear-gradient(
  90deg,
  rgb(24, 25, 40) 0%,
  rgb(25, 25, 37) 0%,
  rgba(30, 29, 43, 0.96) 50%,
  rgb(16, 17, 27) 100%
);
$gray-color: #707070;
$clear-gray: #babecc;
$regular-gray: #61677c;
//
$ruler: 16px;
