@import "../../style/scss-variables";

.container {
  display: flex;
  height: auto;
  width: 100%;
  justify-content: flex-end;

  .__space_btn {
    position: relative;
    font-size: 3rem;
    text-transform: uppercase;
    letter-spacing: 0.125em;
    line-height: 1;
    color: #fff;
    margin: 0;
    padding: 1.5rem;
    background: none;
    cursor: pointer;
    appearance: none;
    -webkit-appearance: none;
    border: none;
    &:hover {
      color: lighten($gray-color, 20%);
    }
    &:focus {
      color: lighten($gray-color, 10%);
      outline: none;
    }
    &:active {
      color: $gray-color;
    }

    &::before,
    &::after {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 2px;
      border-radius: 22px;
    }

    &::before {
      top: 0;
      left: 0;
      box-shadow: inset 2px 2px 0 0 $gray-color;
    }
    &::after {
      right: 0;
      bottom: 0;
      box-shadow: inset -2px -2px 0 0 $gray-color;
    }
    &:hover {
      &::before {
        animation: hoverShadowBefore 1s forwards;
      }
      &::after {
        animation: hoverShadowAfter 1s forwards;
      }
    }
  }
}
@keyframes hoverShadowBefore {
  0% {
    width: 100%;
    height: 2px;

    top: 0;
    left: 0;
  }
  33% {
    width: 2px;
    height: 100%;

    top: 0;
    left: 0;
  }
  66% {
    width: 2px;
    height: 2px;

    top: calc(100% - 1px);
    left: 0;
  }
  100% {
    width: 100%;
    height: 2px;

    top: calc(100% - 2px);
    left: 0;
  }
}

@keyframes hoverShadowAfter {
  0% {
    width: 100%;
    height: 2px;
  }
  33% {
    width: 2px;
    height: 100%;

    bottom: 0;
    right: 0;
  }
  66% {
    width: 2px;
    height: 2px;

    bottom: calc(100% - 1px);
    right: 0;
  }
  100% {
    width: 100%;
    height: 2px;

    bottom: calc(100% - 1px);
    right: 0;
  }
}

///////////////////////// RESPONSIVE ///////////////////////////
@media only screen and (max-width: 950px) and (min-width: 600px) {
  .container {
    justify-content: center;
  }
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .container {
    justify-content: center;
  }
}

@media only screen and (max-width: 600px) {
  .container {
    justify-content: center;
    .__space_btn {
      font-size: 27px;
      line-height: 10px;
    }
  }
}

@media (max-width: 375px) {
  .container {
    justify-content: center;
    .__space_btn {
      font-size: 21px;
      line-height: 2px;
    }
  }
}
