@import "../../style/scss-variables";

.__container_form {
  background: $color-bg;
  border-radius: 19px;
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: $ruler;

  .__form_element {
    margin-top: 0.5rem;
    display: flex;
    flex-direction: column;

    label {
      color: grey;
      font-size: 1.5rem;
      text-align: left;
      margin-bottom: 1rem;
    }

    input,
    textarea {
      font-size: $ruler;
      border-radius: 320px;
      padding: 19px;
      letter-spacing: -0.2px;
      box-shadow: inset 2px 2px 5px $clear-gray, inset -5px -5px 10px #fff;
      width: 100%;
      appearance: none;
      -webkit-appearance: none;
      resize: none;
      outline: none;
      border: none;
      height: 4rem;
      background-color: $color-bg;
      cursor: pointer;
      overflow: auto;

      &:focus {
        box-shadow: inset 1px 1px 2px $clear-gray, inset -1px -1px 2px #fff;
      }
    }
  }

  label {
    display: block;
  }

  button {
    color: $regular-gray;
    box-shadow: -5px -5px 20px #fff, 5px 5px 20px $clear-gray;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    font-weight: 600;
    width: 14rem;
    height: auto;
    border: 0;
    outline: 0;
    font-size: $ruler;
    border-radius: calc(#{$ruler} * 20);
    padding: $ruler;
    background-color: $color-bg;
    text-shadow: 1px 1px 0 #fff;
    letter-spacing: -0.2px;
    margin: 18px auto;

    &:hover {
      box-shadow: -2px -2px 5px #fff, 2px 2px 5px $clear-gray;
    }

    &:active {
      box-shadow: inset 1px 1px 2px $clear-gray, inset -1px -1px 2px #fff;
    }
  }
  div {
    color: $regular-gray;
  }
}
