@import "../../style/scss-variables";

.about {
  display: flex;
  width: 100%;
  align-items: center;
  height: auto;

  .__container {
    overflow: hidden;
    width: 100%;
    height: auto;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .__about_content {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;

      .box {
        text-align: center;
        display: flex;
        width: 100%;
        height: auto;
        border-radius: 22px;

        &.__anime_container {
          width: 49%;
          overflow: hidden;
          height: auto;
          border: 3px solid $clear-gray;

          .__anime {
            display: block;
            width: 100%;
            height: 100%;
            position: relative;
          }
        }

        &.__text {
          width: 49%;
          position: relative;
          height: 100%;
          white-space: pre-wrap;

          span {
            display: flex;
            align-items: center;
            height: auto;
            text-align: center;
            padding: 3px 0px 11px;
            width: 100%;
            justify-content: center;

            .__slash {
              width: 2px;
              background: $clear-gray;
              transform: rotate(16deg);
              height: 33px;
              margin-left: 14px;
              margin-bottom: 2px;
            }

            .__icon_right,
            .__icon_left {
              position: relative;
              pointer-events: none;
              width: 2rem;
              height: 34px;
            }
          }

          .__regular_text {
            flex-direction: column;
            width: 100%;
            height: 100%;
            align-self: center;
            display: flex;
            text-align: start;
            padding: 2rem;
            line-height: 1.5;
            justify-content: center;

            p {
              font-size: 18px;
              white-space: pre-wrap;
              color: #fff;
            }
          }

          .__bloc_txt_mobile {
            width: 100%;
            height: auto;
            display: none;
            flex-direction: column;

            .__text_mobile {
              flex-direction: column;
              width: auto;
              text-align: start;
              align-self: center;
              justify-content: center;
              display: none;
              padding: 20px 0;
              width: 100%;
              line-height: 29px;
              p {
                font-size: 16px;
                color: #fff;
              }

              h1 {
                font-size: 18px;
                line-height: 25px;
              }

              h2 {
                font-size: 17px;
                line-height: 25px;
                white-space: pre-wrap;
              }
            }
          }
        }
      }
    }
  }
}
//////////////////RESPONSIVE//////////////////////
@media only screen and (max-width: 1440px) and (min-width: 1200px) {
  .about {
    .__container {
      .__about_content {
        .box {
          &.__text {
            .__regular_text {
              padding: 1rem;
              display: flex;
            }
            .__bloc_txt_mobile {
              display: none;
              .__text_mobile {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1201px) and (min-width: 600px) {
  .about {
    .__container {
      .__about_content {
        flex-direction: column;
        height: 100vh;
        .box {
          text-align: center;
          display: flex;

          &.__anime_container {
            width: 100%;
            height: 100vh;
          }
          &.__text {
            width: 100%;
            .__regular_text {
              display: none;
            }
            .__bloc_txt_mobile {
              display: block;
              .__text_mobile {
                display: block;
                padding: 30px;
                height: auto;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .about {
    height: 100vh;
    .__container {
      .__about_content {
        .box {
          &.__text {
            height: 100%;
            .__regular_text {
              display: block;
              padding: 1rem;
            }
            .__bloc_txt_mobile {
              display: none;
              .__text_mobile {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .about {
    height: 100vh;
    height: auto;
    .__container {
      .__about_content {
        flex-direction: column;
        .box {
          &.__anime_container {
            width: 100%;
            height: 75vh;
          }
          &.__text {
            width: 100%;
            span {
              .__slash {
                width: 1.4px;
                height: 21px;
                margin-left: 11px;
              }
              .__icon_right,
              .__icon_left {
                width: 1.3rem;
                height: 25px;
              }
            }
            .__regular_text {
              display: none;
            }
            .__bloc_txt_mobile {
              display: block;
              .__text_mobile {
                display: block;
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .about {
    .__container {
      .__about_content {
        .box {
          &.__text {
            overflow-x: hidden;
            overflow-y: scroll;
            &::-webkit-scrollbar {
              width: 12px;
            }
            &::-webkit-scrollbar-track {
              border: 1px solid #babecc;
              border-radius: 3px;
              border-radius: 2px 22px 22px 2px;
            }
            &::-webkit-scrollbar-thumb {
              background: #babecc;
              border-radius: 2px 22px 22px 2px;
            }
            &::-webkit-scrollbar-thumb:hover {
              background: #babecc;
            }
            height: 100%;
            .__regular_text {
              display: block;
              padding: 1rem;
            }
            .__bloc_txt_mobile {
              display: none;
              .__text_mobile {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}
