.maintenance {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  div {
    margin-top: 15%;
    :nth-child(2) {
      font-size: 18px;
    }

    p {
      color: #fff;
      margin-top: 10px;
    }
  }

  div > img {
    width: 200px;
  }
}
