.arrow {
  width: 100%;
  height: auto;
  display: none;
  position: relative;
  align-items: center;
  bottom: 0;
  cursor: pointer;
  justify-content: center;
  img {
    width: 4rem;
    height: 100%;
  }
}

//////////////////////// RESPONSIVE ///////////////////////////////
@media only screen and (max-width: 820px) {
  .arrow {
    display: flex;
  }
}
