@import "../../style/scss-variables";

.__iconBox {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: space-around;

  .unit {
    box-shadow: -2px -2px 20px $color-clear-dark,
      2px 2px 20px $color-shadow-dark;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    width: 64px;
    height: 64px;
    border: none;
    border: 0;
    outline: 0;
    border-radius: 30%;
    padding: 14px;
    background-color: transparent;
    appearance: none;
    -webkit-appearance: none;

    &:hover {
      box-shadow: -2px -2px 2px $color-clear-dark,
        2px 2px 2px $color-shadow-dark;
    }

    &:active {
      box-shadow: inset 1px 1px 2px $color-shadow-dark,
        inset -1px -1px 1px $color-clear-dark;
    }

    .icon {
      margin-right: 0;
      width: 2rem;
    }
  }
}

//////////////////////// RESPONSIVE ///////////////////////////////
@media only screen and (max-width: 1440px) {
  .__iconBox {
    justify-content: space-between;
  }
}

@media only screen and (max-width: 768px) {
  .__iconBox {
    justify-content: space-evenly;
  }
}
